<template>
  <div
    class="flex"
    :class="variant"
  >
    <div
      v-if="currentPage > 0 && limit > 0 && totalCount > 0"
      class="mt-1 mr-4"
    >
      {{ startPaginator }} - {{ endPaginator }} of {{ totalCount }}
    </div>
    <slot v-bind="{ ...slotProps }">
      <ul
        class="list"
        aria-label="Pagination"
      >
        <li
          v-if="showFirstPageBtn"
          class="node"
          :class="[
            pageShiftNodeClass,
            currentPage === 1 ? disabledNodeClass : '',
            size
          ]"
          @click="setCurrentPage(1)"
        >
          <slot name="firstPageBtn">
            First
          </slot>
        </li>
        <li
          v-if="showPreviousPageBtn"
          class="node page-shit-node"
          :class="[
            currentPage === 1 ? disabledNodeClass : '',
            pageShiftNodeClass,
            size
          ]"
          @click="setCurrentPage(currentPage - 1)"
        >
          <slot name="previousPageBtn">
            <base-svg
              src="icons/arrowLeft.svg"
              :svg-attributes="{
                class: 'h-4 w-4'
              }"
            />
          </slot>
        </li>
        <!-- larger screens layout -->
        <li
          v-for="(pageVal, pIndex) in pagesArr"
          :key="pIndex"
          class="node hidden sm:inline-flex"
          :class="[
            pageVal === currentPage ? selectedNodeClass : '',
            pageNumberNodeClass,
            size
          ]"
          @click="setCurrentPage(pageVal)"
        >
          {{ pageVal }}
        </li>
        <!-- mobile layout -->
        <li
          class="relative sm:hidden items-center"
          :class="[
            pageNumberNodeClass,
            selectedNodeClass,
            size
          ]"
        >
          {{ currentPage }}
        </li>
        <li
          v-if="showNextPageBtn"
          class="node"
          :class="[
            currentPage === totalPages ? disabledNodeClass : '',
            pageShiftNodeClass,
            size
          ]"
          @click="setCurrentPage(currentPage + 1)"
        >
          <slot name="nextPageBtn">
            <base-svg
              src="icons/arrowRight.svg"
              :svg-attributes="{
                class: 'h-4 w-4'
              }"
            />
          </slot>
        </li>
        <li
          v-if="showLastPageBtn"
          class="node"
          :class="[
            currentPage === totalPages ? disabledNodeClass : '',
            pageShiftNodeClass,
            size
          ]"
          @click="setCurrentPage(totalPages)"
        >
          <slot name="lastPageBtn">
            Last
          </slot>
        </li>
      </ul>
    </slot>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import BaseSvg from './BaseSvg.vue';

export default {
    name: 'BasePagination',

    components: {
        BaseSvg
    },

    props: {
        limit: {
            type: Number,
            default: 0,
            description: 'bound value(limit from display selector)'
        },
        totalCount: {
            type: Number,
            default: 0,
            description: 'bound value(total Count of all the data)'
        },
        modelValue: {
            type: Number,
            default: 1,
            description: 'bound value(current selected page)'
        },
        totalPages: {
            type: Number,
            required: true,
            description: 'total number of pages'
        },
        displayLimit: {
            type: Number,
            default: 7,
            description: 'limits the nodes to be displayed in component(if useEllipsis is set to true, keep min value upto 5 due to accessibility reasons), value is ignored for small devices, only 1 node is shown for better visibility'
        },
        useEllipsis: {
            type: Boolean,
            default: true,
            description: 'shows ellipsis if all nodes can \'t fit(depending on totalPages and displayLimit)'
        },
        showFirstPageBtn: {
            type: Boolean,
            default: true,
            description: 'toggles first page button'
        },
        showLastPageBtn: {
            type: Boolean,
            default: true,
            description: 'toggles last page button'
        },
        showPreviousPageBtn: {
            type: Boolean,
            default: true,
            description: 'toggles previous button'
        },
        showNextPageBtn: {
            type: Boolean,
            default: true,
            description: 'toggles next button'
        },
        selectedNodeClass: {
            type: String,
            default: 'selected',
            description: 'classes applied for selected node'
        },
        pageNumberNodeClass: {
            type: String,
            default: 'page-number-node',
            description: 'default classes applied to all page nodes'
        },
        disabledNodeClass: {
            type: String,
            default: 'disabled-node',
            description: 'classes applied to disabled nodes'
        },
        pageShiftNodeClass: {
            type: String,
            default: 'page-shift-node',
            description: 'default classes applied to first and last page nodes'
        },
        size: {
            type: String,
            default: 'md',
            description: 'size of pagination(check size in style section)'
        },
        variant: {
            type: String,
            default: 'pagination-default',
            description: 'applies styling to whole pagination component(check variants in style block)'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { emit }) {
        // current page logic - 2 way binding of selected page
        const currentPage = ref(1);
        // parent to child binding
        onMounted(() => {
            currentPage.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            () => {
                currentPage.value = props.modelValue;
            }
        );
        // child to parent binding
        const setCurrentPage = (pageValue) => {
            if (currentPage.value !== pageValue && typeof pageValue === 'number' && pageValue > 0 && pageValue <= props.totalPages) {
                currentPage.value = pageValue;
                emit('update:modelValue', currentPage.value);
            }
        };

        const startPaginator = computed(() => {
            if (currentPage.value > 1) {
                if ((props.limit * currentPage.value) + 1 > props.totalCount) {
                    return ((props.limit * currentPage.value) + 1) - props.limit;
                } else {
                    return (props.limit * (currentPage.value - 1)) + 1;
                }
            } else {
                return 1;
            }
        });

        const endPaginator = computed(() => {
            if (props.limit > props.totalCount) {
                return props.totalCount;
            } else {
                if (currentPage.value > 1) {
                    if ((props.limit * currentPage.value) + props.limit > props.totalCount) {
                        return props.totalCount;
                    } else {
                        return (props.limit * (currentPage.value - 1)) + props.limit;
                    }
                }
            }
            return props.limit;
        });

        // pages array generation logic - generates array based on currentPage, totalPages, displayLimit and useEllipsis values
        const ellipsisOnSecondElement = computed(() => {
            return props.useEllipsis && currentPage.value > props.displayLimit - 2;
        });
        const ellipsisOnSecondLastElement = computed(() => {
            return props.useEllipsis && currentPage.value < props.totalPages - 2;
        });
        const pagesArr = computed(() => {
            if (props.totalPages > props.displayLimit) {
                // use ellipsis only if display limit is greater than 5
                if (props.useEllipsis && props.displayLimit > 5) {
                    return Array.from(
                        {
                            length: props.displayLimit
                        },
                        (val, index) => {
                            // start index from 1
                            const pageIndex = index + 1;
                            // apply ellipsis on second element
                            if (ellipsisOnSecondElement.value && pageIndex === 2) {
                                return '...';
                            }
                            // apply ellipsis on second last element
                            if (ellipsisOnSecondLastElement.value && pageIndex === props.displayLimit - 1) {
                                return '...';
                            }
                            // last page
                            if (pageIndex === props.displayLimit) {
                                return props.totalPages;
                            }
                            // first page
                            if (pageIndex === 1) {
                                return pageIndex;
                            }
                            // remaining page values
                            if (currentPage.value >= props.displayLimit - 1) {
                                if (currentPage.value >= props.totalPages - 1) {
                                    return props.totalPages - (props.displayLimit - pageIndex);
                                }
                                return currentPage.value - props.displayLimit + pageIndex + 2;
                            }
                            return pageIndex;
                        }
                    );
                }
                return Array.from(
                    {
                        length: props.displayLimit
                    },
                    (val, index) => currentPage.value >= props.displayLimit ? Math.abs(currentPage.value - props.displayLimit) + index + 1 : (index + 1)
                );
            } else {
                return Array.from(
                    {
                        length: props.totalPages
                    },
                    (val, index) => index + 1
                );
            }
        });

        const slotProps = {
            currentPage,
            setCurrentPage,
            pagesArr,
            ...props
        };

        return {
            currentPage,
            setCurrentPage,
            pagesArr,
            ellipsisOnSecondElement,
            ellipsisOnSecondLastElement,
            slotProps,
            startPaginator,
            endPaginator
        };
    }
};
</script>

<style scoped>
@layer components {
  /* pagination variants */
  .pagination-default {
    .list {
      @apply relative flex -space-x-px;
    }
    .node {
      @apply relative inline-flex items-center first:rounded-l-sm last:rounded-r-sm border border-custom-gray-8 bg-white text-sm font-normal;
    }
    .page-shift-node {
      @apply text-primary-red hover:bg-gray-50 cursor-pointer;
      &.md {
        @apply px-2.5 py-1.5;
      }
    }
    .page-number-node {
      @apply text-primary-red hover:bg-gray-50 cursor-pointer;
      &.md {
        @apply px-3.5 py-1.5;
      }
      &.selected {
        @apply bg-primary-red text-white hover:bg-red-500;
      }
    }
    .disabled-node {
      @apply bg-white text-custom-gray-3 cursor-not-allowed hover:bg-transparent;
    }
  }
}
</style>
